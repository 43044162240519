import {
    SET_TOKEN,
    SET_USER_DATA,
    SET_USER_STATE,
    SET_LOGOUT,
    SET_LAT_LNG,
    SET_OTP_TIMER,
    SET_USER_PHONE
} from '../constants/user';

const initState = {
    token: null,
    user: null,
    state: null,
    latlong: null,
    otp: 0,
    phone: ""
}

const auth = (state = initState, action) => {
    switch (action.type) {
        case SET_TOKEN:
            return {
                ...state,
                token: action.payload
            }
        case SET_USER_DATA:
            return {
                ...state,
                user: action.payload
            }
        case SET_USER_STATE:
            return {
                ...state,
                state: action.payload
            }
        case SET_LOGOUT:
            return {
                ...state,
                token: action.payload,
                user: action.payload
            }
        case SET_LAT_LNG:
            return {
                ...state,
                latlong: action.payload
            }
        case SET_OTP_TIMER:
            return {
                ...state,
                otp: action.payload
            }
        case SET_USER_PHONE:
            return {
                ...state,
                phone: action.payload
            }
        default:
            return state;
    }
}

export default auth