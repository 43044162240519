import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setOtpTimer, setToken, setUserData, setUserPhone} from "../../redux/actions/user";
import {postSellerConfirmApi, postSellerLoginApi} from "../../api/api";
import Style from "../../assets/css/Seller.module.css"
import { Button, Input, message, Col, Row, Form } from 'antd';
import { ClockCircleOutlined, ArrowLeftOutlined, CheckOutlined } from '@ant-design/icons';
import {useNavigate} from "react-router-dom";
import AuthLayout from "../../layout/auth";
import LoginSteps from "../../layout/auth/components/loginSteps";

const Login = () => {
    const Router = useNavigate();
    const dispatch = useDispatch();
    const [code, setCode] = useState("");
    const [processing, setProcessing] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const state = useSelector((state)=>state?.auth)
    const initialTime = state?.otp;
    const userPhone = state?.phone;
    const [currentStep, setCurrentStep] = useState(0);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [form] = Form.useForm();

    useEffect(()=>{
        if(initialTime > 0){
            setConfirm(true)
        }
    },[initialTime])

    useEffect(() => {
        if ("OTPCredential" in window) {
            const ac = new AbortController();

            navigator.credentials
                .get({
                    otp: { transport: ["sms"] },
                    signal: ac.signal,
                })
                .then((otpCredential) => {
                    setCode(otpCredential.code);
                    //message.success("OTP auto-filled successfully!");
                })
                .catch((err) => {
                    if (err.name === "AbortError") {
                        console.warn("OTP request aborted:", err);
                    } else {
                        console.error("OTP Auto-fill error:", err);
                    }
                });

            return () => {
                ac.abort();
            };
        }
    }, []);

    useEffect(() => {
        if (initialTime > 0) {
            const interval = setInterval(() => {
                dispatch(setOtpTimer(initialTime - 1))
            }, 1000);

            return () => clearInterval(interval);
        } else {
        }

    }, [initialTime]);

    useEffect(()=>{
        if(code){
            handleConfirm(userPhone)
        }
    },[code]);

    useEffect(()=> {
       document.title = "نوبازار - ورود"
    },[]);

    const handleLogin = async({tel}) => {
        setProcessing(true)

        await postSellerLoginApi({tel}).then(()=>{
            message.success("کد تائید با موفقیت ارسال شد");
            setProcessing(false);
            setConfirm(true);
            dispatch(setOtpTimer(90));
            dispatch(setUserPhone(tel))
        }).catch(()=>{
            message.warning("کاربر یافت نشد!");
            setProcessing(false)
        });
    }

    const handleConfirm = async(tel) => {
        setProcessing(true)

        await postSellerConfirmApi({tel, code}).then((confirm)=>{
            setProcessing(false);
            dispatch(setToken(confirm.data.token));
            dispatch(setUserData(confirm.data.user));
            dispatch(setUserPhone(""))
            setConfirm(false)
            dispatch(setOtpTimer(0));
            Router('/dashboard');
            message.success("با موفقیت وارد شدید");
        }).catch(()=>{
            message.error("کد وارد شده صحیح نمی باشد")
            setProcessing(false);
            setCode(null)
        });
    }

    const handleEnter= (e, fn) => {
        let keyCode = e.which || e.key;
        if(keyCode === 13 || keyCode === 'Enter'){
            fn();
        }
    }

    return (
        <AuthLayout>
            <div className="d-flex align-items-center">
                <ArrowLeftOutlined style={{ fontSize: '26px', marginLeft: '10px' }} />
                <h1 className={Style.AuthTitle}>ورود</h1>
            </div>
            <LoginSteps current={currentStep}/>
            {confirm ?
                <div key={2}>
                    <Input.OTP
                        autoFocus
                        type="number"
                        size="large"
                        style={{marginTop: "30px", flexDirection: 'row-reverse', width: "100%"}}
                        length={4}
                        value={code}
                        onChange={setCode}
                    />
                    <small className={Style.Hint} style={{marginTop: "10px", display: "block"}}>کد تائید ارسال شده به شماره {userPhone} را وارد نمائید</small>

                    <Row style={{marginTop: "30px"}}>
                        <Col span={24}>
                            <Button
                                icon={<CheckOutlined />}
                                size="large"
                                type="primary"
                                danger
                                loading={processing}
                                disabled={!code}
                                className="w-100"
                                onClick={()=>handleConfirm(userPhone)}>
                                <span>تائید</span>
                            </Button>
                            <Button
                                size="large"
                                style={{marginTop: "10px"}}
                                disabled={initialTime > 0 ? true : false}
                                className="w-100"
                                onClick={()=> (setConfirm(false), setCurrentStep(0))}>
                                <ClockCircleOutlined spin={initialTime ? true : false }/>
                                <span>ارسال مجدد</span>
                                <bdi style={{marginRight: "5px"}}>{initialTime > 0 ? initialTime : null}</bdi>
                            </Button>
                        </Col>
                    </Row>
                </div>
                :
                <div key={1}>
                    <Form
                        style={{ marginTop: "30px" }}
                        onFinish={handleLogin}
                        form={form}
                        initialValues={{tel: userPhone}}
                    >
                        <Form.Item
                            name="tel"
                            rules={[
                                { required: true, message: "لطفاً شماره موبایل را وارد کنید" },
                                { pattern: /^09\d{9}$/, message: "شماره موبایل باید ۱۱ رقم و معتبر باشد" }
                            ]}
                        >
                            <Input
                                autoFocus
                                size="large"
                                className="letter-space dir-ltr text-center"
                                style={{ marginBottom: "10px" }}
                                placeholder="09---------"
                                maxLength={11}
                                onKeyPress={(e) => {
                                    if (!/[0-9]/.test(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                                onChange={(e) => {
                                    const onlyNumbers = e.target.value.replace(/\D/g, "").slice(0, 11);
                                    setPhoneNumber(onlyNumbers);
                                }}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button
                                htmlType="submit"
                                size="large"
                                style={{ marginTop: "10px" }}
                                type="primary"
                                danger
                                loading={processing}
                                className="w-100 mt-3 btn-line-success"
                            >
                                <span>ورود</span>
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            }
        </AuthLayout>
    )
}

export default Login;