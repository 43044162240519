import React, {useEffect, useState} from "react";
import styles from "../../assets/css/Seller.module.css";
import SellerLayout from "../../layout/seller";
import AdsStyle from "../../assets/css/ads.module.css";
import Scripts from "../../api/scripts";
import Helper from "../../api/helper";
import {useDispatch, useSelector} from "react-redux";
import {
    deleteAdsImageByIdApi,
    deleteVideoByIdApi,
    getAdsByIdApi,
    putSellerAdsApi,
    putSellerAdsOptionApi,
    uploadVideoApi,
    postSellerAdsApi,
    postSellerAdsOptionApi,
    getProvinceApi,
    getCityByProvinceApi,
    getDistrictByIdApi,
    getCategoryApi,
    getSubCategoryApi,
    getSubSubCategoryApi,
    getBrandApi,
    getModelApi
} from "../../api/api";
import {Root} from "../../api/setting";
import {Select, Modal, Button, notification, Input, Upload, Progress, Spin, Tag, Row, Col} from 'antd';
import { VideoCameraAddOutlined, ClockCircleOutlined, CheckCircleOutlined, PlusOutlined, SettingOutlined } from '@ant-design/icons';
import {setLatLong} from "../../redux/actions/user";
import {filterOption} from "../../api/common";
import {useNavigate, useParams} from "react-router-dom";
import Map from "../../components/seller/map";
import Num2persian from "num2persian";
const { Dragger } = Upload;


const NewAds = () => {

    const Navigate = useNavigate();
    const params = useParams();
    const { id } = params;
    const token = useSelector((state)=> state.auth.token) || null;
    const latlng = useSelector((state)=> state.auth.latlng) || null;
    const dispatch = useDispatch();

    const [ads_id, setAds_id] = useState(null);
    const [adsId, setAdsId] = useState('');
    const [userId, setUserId] = useState('');
    const [processing, setProcessing] = useState(true);
    const [loading, setLoading] = useState(true);
    const [adsType, setAdsType] = useState(null);
    const [selectLoading, setSelectLoading] = useState(null);
    const [errors, setErrors] = useState([]);

    const [adsData, setAdsData] = useState({});

    const [province, setProvince] = useState([]);
    const [city, setCity] = useState([]);
    const [district, setDistrict] = useState([]);

    const [category, setCategory] = useState([]);
    const [subcategory, setSubcategory] = useState([]);
    const [subsubcategory, setSubsubcategory] = useState([]);

    const [brand, setBrand] = useState([]);
    const [model, setModel] = useState([]);

    const [carYear, setCarYear] = useState([]);
    const [carColor, setCarColor] = useState([]);
    const [stateOptions, setStateOptions] = useState([]);

    const [fileList, setFileList] = useState([]);
    const [targetFile, setTargetFile] = useState({});

    const [successModal, setSuccessModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);

    const [uploadVideoPercentage, setUploadVideoPercentage] = useState(0);
    const [videoLoader, setVideoLoader] = useState(0);
    const [displayVideo, setDisplayVideo] = useState(0);
    const [videoProcessing, setVideoProcessing] = useState(false);
    const [videoBlob, setVideoBlob] = useState(null);
    const [videoFile, setVideoFile] = useState(null);
    const imageKeys = ['img1', 'img2', 'img3', 'img4', 'img5'];

    const isCar = adsData?.cat === 1 && adsData?.subsubcat === 59;
    const isEstate = adsData?.cat === 3 && (adsData?.subcat === 93 || adsData?.subcat === 94 || adsData?.subcat === 95) && (adsData?.subsubcat != 105 && adsData?.subsubcat != 104 && adsData?.subsubcat != 109 && adsData?.subsubcat != 110 && adsData?.subsubcat != 111 && adsData?.subsubcat != 112 && adsData?.subsubcat != 113 && adsData?.subsubcat != 114 && adsData?.subsubcat != 115 && adsData?.subsubcat != 116);

    useEffect(()=>{
        document.title = `نوبازار - ${adsType === "edit" ? `ویرایش آگهی` : `درج آگهی جدید`}`
    },[]);

    useEffect(()=> {
        if(videoProcessing){
            setTimeout(()=>{
                setVideoProcessing(false)
                setVideoBlob(null)
                notification.success({
                    message: "ویدیو با موفقیت پردازش شد",
                    placement: "bottomRight"
                });
            },30000)
        }
    },[videoProcessing])

    useEffect(() => {
        return () => {
            if (videoBlob) {
                URL.revokeObjectURL(videoBlob);
            }
        };
    }, [videoBlob]);

    useEffect(()=>{
        if(id){
            setAds_id(id);
            setAdsType("edit")
            getAdsById(id)
        } else {
            setAdsType("new")
        }
    },[Navigate])

    useEffect(()=>{
        if(adsType === "new"){
            setAdsData({})
            setFileList([])
            setVideoFile(null)
            setDisplayVideo(null)
            setProcessing(false)
            setLoading(false);

            getProvince();
            getCategory();

            getCarOptions();
            getEstateOptions();
        }
    },[adsType]);

    const getAdsById = async(id) => {
        await getAdsByIdApi(id, token).then((ads)=>{
            setAdsData(ads.data);
            setAdsId(ads.data.adsid);
            setUserId(ads.data.user);

            const fileListArray = imageKeys.map((imageKey, index) => {
                if (ads.data[imageKey]) {
                    return {
                        uid: `-${index+1}`,
                        name: imageKey,
                        status: 'done',
                        url: `${Root}/seller/uploads/${ads.data.user}/${ads.data.adsid}/${ads.data[imageKey]}-resized.jpg`,
                    };
                }
                return null;
            }).filter(Boolean);

            setFileList(fileListArray);

            getCarOptions();
            getEstateOptions();
            getProvince();
            getCity(ads.data.province);
            getDistrict(ads.data.city);
            getCategory();
            getSubCategory(ads.data.cat);
            getSubSubCategory(ads.data.subcat);

            if(ads.data.subsubcat){
                getBrand(ads.data.subsubcat);
            }
            if(ads.data.brand){
                getModel(ads.data.brand);
            }
            if(ads.data.latitude && ads.data.longitude){
                dispatch(setLatLong({
                    lat: Number(ads.data.latitude),
                    lng: Number(ads.data.longitude)
                }))
            }
            setDisplayVideo(ads.data.vid ? ads.data.vid : 0)
            setProcessing(false);
            setLoading(false);
        });
    }
    const getCarOptions = () => {
        setCarYear(Helper.AdsCarYear(false));
        setCarColor(Helper.AdsCarColor(false));
    }
    const getEstateOptions = () => {
        setStateOptions(Helper.AdsEstateOptions(false));
    }
    const getProvince = async() => {
        setSelectLoading("province")
        await getProvinceApi().then((p)=>{
            p.data.forEach(item => {item.value = item.id; item.label = item.title});
            setProvince(p.data);
            setSelectLoading(null)
        });
    }
    const getCity = async(id) => {
        setSelectLoading("city")
        await getCityByProvinceApi(id).then((c)=>{
            c.data.forEach(item => {item.value = item.id; item.label = item.title});
            setCity(c.data);
            setSelectLoading(null)
        });
    }
    const getDistrict = async(id) => {
        setSelectLoading("district")
        await getDistrictByIdApi(id).then((d)=>{

            setSelectLoading(null)
            const data = d.data;
            const result = data.reduce((accumulator, item) => {
                item.value = item.id;
                item.label = item.title;

                if (item.dist) {
                    const obj = {
                        label: item.title,
                        options: data.filter(x => !x.dist && x.dis === item.dist)
                    };
                    accumulator.push(obj);
                }

                return accumulator;
            }, []);

            result?.length ? setDistrict(result) : setDistrict(data);
        });
    }
    const getCategory = async() => {
        setSelectLoading("category")
        await getCategoryApi().then((c)=>{
            c.data.forEach(item => {item.value = item.id; item.label = item.name});
            setCategory(c.data);
            setSelectLoading(null)
        });
    }
    const getSubCategory = async(id) => {
        setSelectLoading("subcat")
        await getSubCategoryApi(id).then((sc)=>{
            sc.data.forEach(item => {item.value = item.id; item.label = item.name});
            setSubcategory(sc.data);
            setSelectLoading(null)
        });
    }
    const getSubSubCategory = async(id) => {
        setSelectLoading("subsubcat")
        await getSubSubCategoryApi(id).then((ssc)=>{
            ssc.data.forEach(item => {item.value = item.id; item.label = item.name});
            setSubsubcategory(ssc.data);
            setSelectLoading(null)
        });
    }
    const getBrand = async(id) => {
        setSelectLoading("brand")
        await getBrandApi(id).then((brand)=>{
            brand.data.forEach(item => {item.value = item.id; item.label = item.name});
            setBrand(brand.data);
            setSelectLoading(null)
        });
    }
    const getModel = async(id) => {
        setSelectLoading("model")
        await getModelApi(id).then((model)=>{
            model.data.forEach(item => {item.value = item.id; item.label = item.name});
            setModel(model.data);
            setSelectLoading(null)
        });
    }
    const handleProvince = (prov) => {
        setErrors(null)
        setAdsData({...adsData, province: prov, city: null, district: null})
        getCity(prov);
    }
    const handleCity = (city) => {
        setErrors(null)
        setAdsData({...adsData, city: city, district: null})
        getDistrict(city);
    }
    const handleDistrict = (district) => {
        setAdsData({...adsData, district: district})
    }
    const handleCategory = (category) => {
        setErrors(null)
        setAdsData({...adsData, cat: category, subcat: null, subsubcat: null, brand: null, model: null})
        getSubCategory(category);
    }
    const handleSubCategory = (sub_category) => {
        setErrors(null)
        setAdsData({...adsData, subcat: sub_category, subsubcat: null, brand: null, model: null})
        getSubSubCategory(sub_category);
    }
    const handleSubSubCategory = (sub_sub_category) => {
        setAdsData({...adsData, subsubcat: sub_sub_category, brand: null, model: null})
        getBrand(sub_sub_category);
    }
    const handleBrand = (brand_id) => {
        setAdsData({...adsData, brand: brand_id, model: null})
        getModel(brand_id);
    }
    const handleModel = (model_id) => {
        setAdsData({...adsData, model: model_id})
    }
    const handlePriceType = (price_type) => {
        setAdsData({...adsData,
            pricetype: price_type,
            price: '',
            options: {
                ...adsData.options,
                estate_price_rent: '',
                estate_price_m: ''
            }
        });
    }
    const handleEstateOptions = (options) => {
        setAdsData({...adsData,
            options: {...adsData.options, estate_option: options.toString(options)}
        })
    }
    const handleRedirect = (url) => {
        if(url){
            Navigate(url)
        } else {
            window.location.reload();
        }

    }
    const previewVideo = (selectedFile) => {
        setVideoFile(selectedFile);
        const blob = URL.createObjectURL(selectedFile);
        setVideoBlob(blob);
    }

    const uploadVideo = async (userId, adsId) => {

        const formData = new FormData();
        formData.append('video', videoFile);
        formData.append('seller', userId);
        formData.append('ads', adsId);

        setVideoLoader(1)
        const conf = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + token
            },
            onUploadProgress: (progressEvent) => {
                if (progressEvent) {
                    const percent = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    setUploadVideoPercentage(percent)
                }
            }
        }

        await uploadVideoApi(formData, token, conf).then(()=>{
            setVideoLoader(2)
            setTimeout(()=>{
                setVideoLoader(0)
                setDisplayVideo(1)
                setVideoProcessing(true)
                notification.success({
                    message: "ویدیو با موفقیت آپلود و در حال پردازش می باشد",
                    placement: "bottomRight"
                });
                setAdsData({...adsData, vid: 1})
                if(adsType === "new"){
                    setSuccessModal(true);
                }
            },1000);
        }).catch((error)=>{
            console.log(error)
        });
    }
    const handleUrl = (v) => {
        const valid = Scripts.validateURL(v);
        if(!valid){
            setAdsData({...adsData, url: ""})
            notification.error({
                message: "لینک وارد شده معتبر نمی باشد",
                placement: "bottomRight",
            })

        }
    }
    const handleCalculate = () => {
        let met = Math.floor(adsData.price / adsData?.options?.estate_area);
        setAdsData({...adsData, options: {...adsData.options, estate_price_m: met}})
    }
    const ColoredSelectOption = ({ label, color }) => (
        <div>
            <bdi style={{ backgroundColor: color, width: '10px', height: '10px', borderRadius: '50%', display: 'inline-block', marginRight: '5px' }} />
            <span style={{marginRight: "10px"}}>{label}</span>
        </div>
    )

    const uploadProps = {
        disabled: videoProcessing,
        name: 'file',
        multiple: false,
        showUploadList: false,
        accept: 'video/*;capture=camera',
        customRequest(e){
            previewVideo(e.file)
        }
    }
    const customRequest = ({ file, onSuccess, onError }) => {

        const missingKeys = imageKeys.filter(key => !fileList.some(obj => obj.name === key));
        const randomIndex = Math.floor(Math.random() * missingKeys.length);
        const pickedName = missingKeys[randomIndex];

        const reader = new FileReader();
        reader.onload = (e) => {
            const previewImage = e.target.result;
            setFileList((prevList) =>
                [...prevList, { uid: file.uid, preview: previewImage, originFileObj: file, name: pickedName}]
            );
            onSuccess();
        };
        reader.readAsDataURL(file);
    }
    const handleRemove = (file) => {
        const name = file.name;

        if(!file.originFileObj){
            setDeleteModal(true)
            setTargetFile(name)
            handleRemoveImageObj(name)
        } else {
            handleRemoveImageObj(name)
        }
    }
    const handleRemoveImageObj = (name) => {
        const newArray = fileList.filter(item => item.name !== name);
        setAdsData({...adsData, [name]: null})
        setFileList(newArray)
    }
    const handleRemoveImage = async (name) => {
        const data = {
            name: name
        }

        await deleteAdsImageByIdApi(adsId, data, token).then(()=>{
            notification.success({
                message: "تصویر با موفقیت حذف شد",
                placement: "bottomRight"
            });
            setDeleteModal(false)
            handleRemoveImageObj(targetFile);
        }).catch(()=>{
            setDeleteModal(false)
            notification.error({
                message: "خطا",
                placement: "bottomRight"
            });
        })
    }
    const handleRemoveVideo = async () => {
        await deleteVideoByIdApi(adsId, token).then(()=>{
            notification.success({
                message: "ویدیو با موفقیت حذف شد",
                placement: "bottomRight"
            });
            setDisplayVideo(0)
            setVideoFile(null)
        }).catch((error)=>{
            console.log(error)
        })
    }

    const handlePostAds = async(data) => {
        let ads;
        await postSellerAdsApi(data, token).then((r)=>{
            setAds_id(r.data.id);
            setAdsId(r.data.adsid);
            setUserId(r.data.user);

            if(videoFile){
                uploadVideo(r.data.user, r.data.adsid);
            } else {
                setSuccessModal(true);
            }
            ads = r.data;
        }).catch((error)=>{
            notification.error({
                message: "لطفا اطلاعات را کامل وارد کنین",
                placement: "bottomRight"
            });
            setProcessing(false)
            if(error?.response?.data?.error){
                setErrors(Object.keys(error?.response?.data?.error))
            }
        });
        return ads;
    }

    const handleUpdateAds = async (data) => {
        await putSellerAdsApi(ads_id, data, token).then(()=>{
            setSuccessModal(true);
        }).catch((error)=>{
            notification.error({
                message: "لطفا اطلاعات را کامل وارد کنین",
                placement: "bottomRight"
            });
            setProcessing(false)
            if(error?.response?.data?.error){
                setErrors(Object.keys(error?.response?.data?.error))
            }
        });
    }

    const handlePostAdsOption = async (optionsData) => {
        await postSellerAdsOptionApi(optionsData, token).then(()=>{
            setSuccessModal(true);
        }).catch(()=>{
            setProcessing(false);
            notification.error({
                message: "لطفا اطلاعات را کامل وارد کنین",
                placement: "bottomRight"
            });
            return false;
        })
    }

    const handleUpdateAdsOption = async (optionsData) => {
        await putSellerAdsOptionApi(adsId, optionsData, token).then(()=>{
            setSuccessModal(true);
        }).catch(()=>{
            setProcessing(false);
            notification.error({
                message: "لطفا اطلاعات را کامل وارد کنین",
                placement: "bottomRight"
            });
            return false;
        })
    }

    const handleCheckAds = () => {
        setProcessing(true);

        let data = {...adsData};

        if(latlng) {
            data = {
                ...data,
                latitude: String(latlng.lat),
                longitude: String(latlng.lng)
            }
        }
        if(fileList){
            data = {
                ...data,
                img: fileList
            }
        }

        const {options} = data;

        if(isCar) {
            if(adsData?.model && adsData?.brand && adsData?.options?.car_year && adsData?.options?.car_mileage && adsData?.options?.car_color) {

                const {car_year, car_mileage, car_color} = options;

                if(adsType === "new"){
                    handlePostAds(data).then((ads)=>{
                        const optionsData = {
                            car_year: car_year,
                            car_mileage: car_mileage,
                            car_color: car_color,
                            adsid: ads.adsid
                        }
                        handlePostAdsOption(optionsData)
                    })

                } else if(adsType === "edit"){
                    const optionsData = {
                        car_year: car_year,
                        car_mileage: car_mileage,
                        car_color: car_color,
                        adsid: adsId
                    }
                    handleUpdateAds(data).then(()=>{
                        handleUpdateAdsOption(optionsData)
                    })
                }

            } else {
                setProcessing(false);
                notification.error({
                    message: "لطفا اطلاعات را کامل وارد کنین",
                    placement: "bottomRight"
                });
                return false;
            }
        } else if(isEstate) {

            if(adsData?.options?.estate_area && adsData?.options?.estate_floor && adsData?.options?.estate_age && adsData?.options?.estate_bed) {

                const {estate_area, estate_floor, estate_age, estate_bed, estate_option, estate_price_m, estate_price_rent} = options;

                if(adsType === "new"){
                    handlePostAds(data).then((ads)=>{
                        const optionsData = {
                            estate_area: estate_area,
                            estate_floor: estate_floor,
                            estate_age: estate_age,
                            estate_bed: estate_bed,
                            estate_option: estate_option,
                            estate_price_m: estate_price_m,
                            estate_price_rent: estate_price_rent,
                            adsid: ads.adsid
                        }
                        handlePostAdsOption(optionsData)
                    })
                } else if(adsType === "edit"){
                    const optionsData = {
                        estate_area: estate_area,
                        estate_floor: estate_floor,
                        estate_age: estate_age,
                        estate_bed: estate_bed,
                        estate_option: estate_option,
                        estate_price_m: estate_price_m,
                        estate_price_rent: estate_price_rent,
                        adsid: adsId
                    }
                    handleUpdateAds(data).then(()=>{
                        handleUpdateAdsOption(optionsData)
                    })
                }

            } else {
                setProcessing(false);
                notification.error({
                    message: "لطفا اطلاعات را کامل وارد کنین",
                    placement: "bottomRight"
                });
                return false;
            }
        } else {
            if(subsubcategory?.length && !adsData.subsubcat){
                setProcessing(false);
                notification.error({
                    message: "لطفا اطلاعات را کامل وارد کنین",
                    placement: "bottomRight"
                });
                return false;
            }

            if(district?.length && !adsData.district){
                setProcessing(false);
                notification.error({
                    message: "لطفا اطلاعات را کامل وارد کنین",
                    placement: "bottomRight"
                });
                return false;
            }
            if(adsType === "new"){
                handlePostAds(data)
            } else if(adsType === "edit"){
                handleUpdateAds(data)
            }
        }
    }

    return(
        <>
            <SellerLayout>
                <div className={AdsStyle.AdsWrapper}>
                    <section style={{marginBottom: "10px"}}>
                        <div className={`${styles.GlobalTitle} d-flex align-items-center`}>
                            <h2>{adsType === "edit" ?  `ویرایش آگهی ${adsData?.title ? adsData?.title : "..."}` : adsType === "new" ? "آگهی جدید" : null}</h2>
                            {loading ? <Spin style={{marginRight: "10px"}}/> : null}
                        </div>
                        <div className={styles.NewAds}>
                            <h6 className={styles.Required}>کجا زندگی میکنی؟</h6>
                            <Row gutter={10}>
                                <Col md={8} span={24}>
                                    <label className={styles.Label}>استان</label>
                                    <Select
                                        value={adsData?.province || null}
                                        className="w-100"
                                        placeholder="استان"
                                        loading={selectLoading == "province" ? true : false}
                                        disabled={!province?.length}
                                        showSearch
                                        filterOption={filterOption}
                                        options={province?.length ? province : [{label: 'همه', value: ''}]}
                                        onChange={(choice) => handleProvince(choice)}
                                        status={errors?.includes("province") ? "error" : null}
                                    />
                                </Col>
                                <Col md={8} span={24}>
                                    <label className={styles.Label}>شهر</label>
                                    <Select
                                        value={adsData?.city || null}
                                        className="w-100"
                                        placeholder="شهر"
                                        loading={selectLoading == "city" ? true : false}
                                        disabled={!adsData?.province || !city?.length ? true : false}
                                        showSearch
                                        filterOption={filterOption}
                                        options={city?.length ? city : [{label: 'همه', value: ''}]}
                                        onChange={(choice)=>handleCity((choice))}
                                        status={errors?.includes("city") ? "error" : null}
                                    />
                                </Col>
                                <Col md={8} span={24}>
                                    <label className={styles.Label}>منطقه</label>
                                    <Select
                                        value={adsData?.district || null}
                                        className="w-100"
                                        placeholder={!district.length ? '-' : 'منطقه'}
                                        loading={selectLoading == "district" ? true : false}
                                        disabled={!adsData?.city || !district?.length ? true : false}
                                        showSearch
                                        filterOption={filterOption}
                                        options={district?.length ? district : [{label: 'همه', value: ''}]}
                                        onChange={(choice)=>handleDistrict((choice))}
                                    />
                                </Col>
                            </Row>
                            <h6 className={styles.Required}>این آگهی تو کدوم دسته بندی هست؟</h6>

                            <Row gutter={10}>
                                <Col md={8} span={24}>
                                    <label className={styles.Label}>دسته بندی</label>
                                    <Select
                                        value={adsData?.cat || null}
                                        className="w-100"
                                        placeholder="دسته بندی"
                                        loading={selectLoading == "category" ? true : false}
                                        disabled={!category?.length}
                                        showSearch
                                        filterOption={filterOption}
                                        options={category?.length ? category : [{label: 'همه', value: ''}]}
                                        onChange={(choice)=>handleCategory((choice))}
                                        status={errors?.includes("cat") ? "error" : null}
                                    />
                                </Col>
                                <Col md={8} span={24}>
                                    <label className={styles.Label}>زیر دسته بندی</label>
                                    <Select
                                        value={adsData?.subcat || null}
                                        className="w-100"
                                        placeholder="زیر دسته بندی"
                                        loading={selectLoading == "subcat" ? true : false}
                                        disabled={!adsData?.cat || !subcategory?.length ? true : false}
                                        showSearch
                                        filterOption={filterOption}
                                        options={subcategory?.length ? subcategory : [{label: 'همه', value: ''}]}
                                        onChange={(choice)=>handleSubCategory((choice))}
                                        status={errors?.includes("subcat") ? "error" : null}
                                    />
                                </Col>
                                <Col md={8} span={24}>
                                    <label className={styles.Label}>زیر گروه</label>
                                    <Select
                                        value={adsData?.subsubcat || null}
                                        className="w-100"
                                        placeholder={!subsubcategory.length ? '-' : 'انتخاب کنید'}
                                        loading={selectLoading == "subsubcat" ? true : false}
                                        disabled={!adsData?.subcat || !subsubcategory?.length ? true : false}
                                        showSearch
                                        filterOption={filterOption}
                                        options={subsubcategory?.length ? subsubcategory : [{label: 'همه', value: ''}]}
                                        onChange={(choice)=>handleSubSubCategory((choice))}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={10}>
                                {brand?.length ?
                                    <Col md={12} span={24}>
                                        <label className={styles.Label}>برند</label>
                                        <Select
                                            value={adsData?.brand || null}
                                            className="w-100"
                                            placeholder={!brand?.length ? '-' : 'انتخاب کنید'}
                                            loading={selectLoading == "brand" ? true : false}
                                            disabled={!adsData?.subsubcat || !brand?.length ? true : false}
                                            showSearch
                                            filterOption={filterOption}
                                            options={brand?.length ? brand : [{label: 'همه', value: ''}]}
                                            onChange={(choice)=>handleBrand((choice))}
                                        />
                                    </Col> : null}
                                {brand?.length ?
                                    <Col md={12} span={24}>
                                        <label className={styles.Label}>مدل</label>
                                        <Select
                                            value={adsData.model || null}
                                            className="w-100"
                                            placeholder={!model.length ? '-' : 'انتخاب کنید'}
                                            loading={selectLoading == "model" ? true : false}
                                            disabled={!adsData?.brand || !model?.length ? true : false}
                                            showSearch
                                            filterOption={filterOption}
                                            options={model?.length ? model : [{label: 'همه', value: ''}]}
                                            onChange={(choice)=>handleModel((choice))}
                                        />
                                    </Col> : null}
                            </Row>

                            {adsData?.cat === 3 && (adsData?.subcat === 93 || adsData?.subcat === 94 || adsData?.subcat === 95) && (adsData?.subsubcat != 105 && adsData?.subsubcat != 104 && adsData?.subsubcat != 109 && adsData?.subsubcat != 110 && adsData?.subsubcat != 111 && adsData?.subsubcat != 112 && adsData?.subsubcat != 113 && adsData?.subsubcat != 114 && adsData?.subsubcat != 115 && adsData?.subsubcat != 116) ?
                                <>
                                    <h6 className={styles.Required}>مشخصات ملک</h6>
                                    <Row gutter={10}>
                                        <Col md={8} span={24}>
                                            <div className="d-flex align-items-center">
                                                <label className={styles.Label}>متراژ</label>
                                                <small className={styles.Hint}>(متر)</small>
                                            </div>
                                            <Input type="number" value={adsData?.options?.estate_area || ""} min={0} placeholder={'مثال: 100'} onChange={(e)=> setAdsData({...adsData, options: {...adsData.options, estate_area: e.target.value}})}/>
                                        </Col>
                                        <Col md={8} span={24}>
                                            <div className="d-flex align-items-center">
                                                <label className={styles.Label}>سن بنا</label>
                                                <small className={styles.Hint}>(سال)</small>
                                            </div>
                                            <Input type="number" value={adsData?.options?.estate_age || ""} min={0} placeholder={'مثال: 5'} onChange={(e)=> setAdsData({...adsData, options: {...adsData.options, estate_age: e.target.value}})}/>
                                        </Col>
                                        <Col md={8} span={24}>
                                            <label className={styles.Label}>طبقه</label>
                                            <Input type="number" value={adsData?.options?.estate_floor || ""} min={0} placeholder={'مثال: 2'} onChange={(e)=> setAdsData({...adsData, options: {...adsData.options, estate_floor: e.target.value}})}/>
                                        </Col>
                                        <Col md={8} span={24}>
                                            <label className={styles.Label}>اتاق</label>
                                            <Input type="number" value={adsData?.options?.estate_bed || ""} min={0} placeholder={'مثال: 2'} onChange={(e)=> setAdsData({...adsData, options: {...adsData.options, estate_bed: e.target.value}})}/>
                                        </Col>
                                        <Col md={8} span={24}>
                                            <label className={styles.Label}>امکانات</label>
                                            <Select
                                                value={adsData?.options?.estate_option?.split(',').map(Number)  || []}
                                                className="w-100"
                                                placeholder="انتخاب کنید"
                                                showSearch
                                                filterOption={filterOption}
                                                mode={"multiple"}
                                                options={stateOptions?.length ? stateOptions : [{label: 'همه', value: ''}]}
                                                onChange={(choice)=>handleEstateOptions(choice)} />
                                        </Col>
                                    </Row>
                                </>
                                : null}

                            {adsData?.cat === 1 && adsData?.subsubcat === 59 ?
                                <>
                                    <h6 className={styles.Required}>مشخصات خودرو</h6>
                                    <Row gutter={10}>
                                        <Col md={8} span={24}>
                                            <label className={styles.Label}>کارکرد</label>
                                            <Input type="number" value={adsData?.options?.car_mileage || ""} min={0} placeholder={'مثال: 10000'} onChange={(e)=> setAdsData({...adsData, options: {...adsData.options, car_mileage: e.target.value}})}/>
                                        </Col>
                                        <Col md={8} span={24}>
                                            <label className={styles.Label}>سال تولید</label>
                                            <Select
                                                value={adsData?.options?.car_year || null}
                                                className="w-100"
                                                placeholder="انتخاب کنید"
                                                showSearch
                                                filterOption={filterOption}
                                                options={carYear?.length ? carYear : [{label: 'همه', value: ''}]}
                                                onChange={(choice)=>setAdsData({...adsData, options: {...adsData.options, car_year: choice}})}
                                            />
                                        </Col>
                                        <Col md={8} span={24}>
                                            <label className={styles.Label}>رنگ</label>
                                            <Select
                                                value={adsData?.options?.car_color || null}
                                                className="w-100"
                                                placeholder="انتخاب کنید"
                                                showSearch
                                                filterOption={filterOption}
                                                onChange={(choice)=>setAdsData({...adsData, options: {...adsData.options, car_color: choice}})}
                                            >
                                                {carColor?.map(({ label, value, color }) => (
                                                    <Select.Option key={value} value={value}>
                                                        <ColoredSelectOption label={label} color={color} />
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Col>
                                    </Row>
                                </>
                                : null}
                        </div>
                    </section>
                    <section>
                        <Row gutter={10}>
                            <Col md={12} span={24}>
                                <Row>
                                    <Col md={24}>
                                        <div className={styles.NewAds}>
                                            <h6 className={styles.Required}>مشخصات آگهی</h6>
                                            <div>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <label className={styles.Label}>عنوان آگهی</label>
                                                    <span className={adsData?.title?.length >= 100 ? styles.Counter + ' ' + styles.Overflow : styles.Counter}>{adsData?.title?.length ? adsData?.title?.length : 0}/100</span>
                                                </div>
                                                <Input
                                                    value={adsData?.title}
                                                    maxLength={100}
                                                    placeholder="مثال: کالای دست دوم"
                                                    onChange={(e)=>setAdsData({...adsData, title: e.target.value})}
                                                    status={errors?.includes("title") ? "error" : null}
                                                />
                                            </div>
                                            <div className="position-relative">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <label className={styles.Label}>شرح آگهی</label>
                                                    <span className={adsData?.description?.length >= 1000 ? styles.Counter + ' ' + styles.Overflow : styles.Counter}>{adsData?.description?.length ? adsData?.description?.length : 0}/1000</span>
                                                </div>
                                                <Input.TextArea
                                                    allowClear
                                                    value={adsData?.description}
                                                    type="textarea"
                                                    maxLength={1000}
                                                    className="ads-textarea"
                                                    placeholder="شرح دهید"
                                                    onChange={(e)=>setAdsData({...adsData, description: e.target.value})}
                                                    status={errors?.includes("description") ? "error" : null}
                                                />
                                            </div>
                                            <div>
                                                <label className={styles.Label}>آیا کالای شما نو است یا کارکرده؟ یا آگهی خدماتی؟</label>
                                                <Select
                                                    value={Helper.AdsType(adsData?.type) || null}
                                                    showSearch={false}
                                                    className="w-100"
                                                    placeholder="انتخاب کنید"
                                                    options={[{label: 'کارکرده', value: 2}, {label: 'نو', value: 1}, {label: 'خدماتی', value: 3}]}
                                                    onChange={(value)=> setAdsData({...adsData, type: value})} />
                                            </div>
                                            <div className="position-relative">
                                                <label className={styles.Label}>قیمت (تومان)</label>
                                                <Row gutter={10}>
                                                    <Col span={16}>
                                                        <Input value={adsData?.price || ""}
                                                               type="number"
                                                               min={0}
                                                               disabled={adsData?.pricetype === 1 ? true : ''}
                                                               maxLength={100}
                                                               placeholder="مثال: 1000"
                                                               onBlur={(e)=> (e.target.value.length <= 0 ? setAdsData({...adsData, pricetype: 1}) : null)}
                                                               onKeyUp={(e)=> (e.target.value.length ? setAdsData({...adsData, pricetype: 2}) : null)}
                                                               onChange={(e)=>setAdsData({...adsData, price: e.target.value})}
                                                        />
                                                        {adsData?.pricetype === 2 ?
                                                            <div style={{marginTop: "10px"}}>
                                                                <Tag color="success"><strong>به حروف:</strong> {Num2persian(adsData?.price)} تومان</Tag>
                                                                <Tag color="success"><strong>به عدد:</strong> {Scripts.formatPrice(adsData?.price)} تومان</Tag>
                                                            </div>
                                                            : null}
                                                    </Col>
                                                    <Col span={8}>
                                                        <Select
                                                            value={adsData.pricetype}
                                                            className="w-100"
                                                            showSearch={false}
                                                            placeholder="نوع قیمت"
                                                            options={[{label: 'مقطوع', value: 2}, {label: 'تماس بگیرید', value: 1}]}
                                                            onChange={(choice)=>handlePriceType(choice)}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                            {adsData?.special === 3 ?
                                                <div>
                                                    <label className={styles.Label}>لینک وب سایت</label>
                                                    <Input
                                                        value={adsData?.url || ""}
                                                        onBlur={(e)=> handleUrl(e.target.value)}
                                                        onChange={(e)=>setAdsData({...adsData, url: e.target.value})}
                                                    />
                                                    <small className={styles.Hint}>نمونه: example.ir | آدرس وارد شده را بدون https یا www وارد کنید</small>
                                                </div> : null}
                                            {adsData?.cat === 3 && adsData?.subcat === 94 ?
                                                <div className={'position-relative'}>
                                                    <div className={'d-flex justify-content-between align-items-center mb-2'}>
                                                        <label className={styles.Label}>قیمت هر متر مربع</label>
                                                        <span className={styles.customIcon} onClick={()=>handleCalculate()}><i className="las la-calculator"></i></span>
                                                    </div>
                                                    <Input
                                                        value={adsData?.options?.estate_price_m || ""}
                                                        type="number"
                                                        min={0}
                                                        disabled={adsData?.pricetype === 1 ? true : false}
                                                        maxLength={100}
                                                        placeholder={'مثال: 1000'}
                                                        onChange={(e)=>setAdsData({...adsData, options: {...adsData.options, estate_price_m: e.target.value}})}
                                                    />
                                                </div>
                                                : null }
                                            {adsData?.cat === 3 && adsData?.subcat === 95 ?
                                                <div className={'position-relative'}>
                                                    <label className={styles.Label}>اجاره</label>
                                                    <Input
                                                        value={adsData?.options?.estate_price_rent || ""}
                                                        type="number"
                                                        min={0}
                                                        disabled={adsData?.pricetype === 1 ? true : false}
                                                        maxLength={100}
                                                        placeholder={'مثال: 1000'}
                                                        onChange={(e)=>setAdsData({...adsData, options: {...adsData.options, estate_price_rent: e.target.value}})}
                                                    />
                                                </div>
                                                : null }
                                        </div>
                                    </Col>
                                    <Col md={24} span={24}>
                                        <div className={styles.NewAds} style={{marginTop: "10px"}}>
                                            <h6 style={{margin: "0px"}}>تصاویر</h6>
                                            <label className={styles.Label}>حداکثر 5 تصویر از آگهی خود آپلود نمایید</label>
                                            <div className={"d-flex flex-wrap"}>
                                                <Upload
                                                    accept={"image/*"}
                                                    customRequest={customRequest}
                                                    onRemove={handleRemove}
                                                    listType="picture-card"
                                                    fileList={fileList}
                                                    maxCount={5}
                                                    showPreviewIcon={false}
                                                >
                                                    {fileList?.length < 5 ?
                                                        <div>
                                                            <PlusOutlined />
                                                        </div>
                                                        : null}
                                                </Upload>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={12} span={24}>
                                <Row>
                                    <Col md={24} span={24}>
                                        <div className={styles.NewAds}>
                                            <h6 style={{margin: "0px"}}>موقعیت خود را روی نقشه مشخص کنید</h6>
                                            <label className={styles.Label}>کلیک کنید، بکشید یا جستجو کنید!</label>
                                            <Map latlng={{lat: adsData?.latitude, lng: adsData?.longitude}}/>
                                        </div>
                                    </Col>
                                    <Col md={24} span={24}>
                                        <div className={styles.NewAds} style={{marginTop: "10px"}}>
                                            <h6 style={{margin: "0px"}}>سایر اطلاعات</h6>
                                            <Row gutter={10}>
                                                <Col md={12} span={24}>
                                                    <label className={styles.Label}>آیا فروش این کالا بصورت اقساطی ممکن هست؟</label>
                                                    <Select
                                                        value={adsData?.installment || null}
                                                        className="w-100"
                                                        placeholder="انتخاب کنید"
                                                        options={[{label: 'بله', value: 2}, {label: 'خیر', value: 1}]}
                                                        onChange={(choice)=>setAdsData({...adsData, installment: choice})}
                                                    />
                                                </Col>
                                                <Col md={12} span={24}>
                                                    <label className={styles.Label}>آیا این کالا بصورت واگذاری و رایگان هست؟</label>
                                                    <Select
                                                        value={adsData?.freead || null}
                                                        showSearch={false}
                                                        className="w-100"
                                                        placeholder="انتخاب کنید"
                                                        options={[{label: 'بله', value: 2}, {label: 'خیر', value: 1}]}
                                                        onChange={(choice)=>setAdsData({...adsData, freead: choice})} />
                                                </Col>
                                                <Col md={12} span={24}>
                                                    <label className={styles.Label}>آیا می تونین این کالا رو سریع تحویل بدین؟</label>
                                                    <Select
                                                        value={adsData?.fast || null}
                                                        showSearch={false}
                                                        className="w-100"
                                                        placeholder="انتخاب کنید"
                                                        options={[{label: 'بله', value: 1}, {label: 'خیر', value: 2}]}
                                                        onChange={(choice)=>setAdsData({...adsData, fast: choice})} />
                                                </Col>
                                                <Col md={12} span={24}>
                                                    <label className={styles.Label}>آیا کالای شما قابل معاوضه هست؟</label>
                                                    <Select
                                                        value={adsData?.exchange || null}
                                                        showSearch={false}
                                                        className="w-100"
                                                        placeholder="انتخاب کنید"
                                                        options={[{label: 'بله', value: 1}, {label: 'خیر', value: 2}]}
                                                        onChange={(choice)=>setAdsData({...adsData, exchange: choice})} />
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </section>
                    <section style={{marginTop: "10px"}}>
                        <div className={styles.NewAds}>
                            <h6 style={{margin: "0px"}}>آپلود ویدیو</h6>
                            <Row gutter={10}>
                                <Col md={8}>
                                    <div>
                                        {uploadVideoPercentage < 100 && uploadVideoPercentage > 0 ?
                                            <Progress percent={uploadVideoPercentage}/>
                                            : null}
                                        <Dragger
                                            {...uploadProps}
                                        >
                                            <p className="ant-upload-drag-icon">
                                                {videoLoader === 1 ? <ClockCircleOutlined spin/>
                                                    : videoLoader === 2 ? <CheckCircleOutlined style={{color: '#52c41a'}} />
                                                        : <VideoCameraAddOutlined />
                                                }
                                            </p>
                                            <p className="ant-upload-text">برای رکورد ویدیو یا انتخاب فایل ویدیو کلیک کنید</p>
                                            <p className="ant-upload-hint">
                                                بعد از انتخاب ویدیو دکمه آپلود را بزنید و بعد از  آپلود ویدیو کمتر از یک دقیقه ویدیو شما پردازش خواهد شد!
                                            </p>
                                        </Dragger>

                                        <div className={"mt-3 d-flex"}>
                                            {videoBlob ?
                                                <Button disabled={videoLoader === 1 || videoProcessing ? true : false} className={"d-flex btn btn-line-success btn btn-secondary font-size-14"} onClick={()=>uploadVideo(userId, adsId)}>
                                                    <span className={"font-size-20"}><i className="las la-plus"></i></span>
                                                    <span>آپلود ویدیو</span>
                                                </Button>
                                                : null}
                                            {videoBlob && !videoProcessing ?
                                                <Button className={"d-flex btn btn-line-danger me-2 btn btn-secondary font-size-14"} onClick={()=>setVideoBlob(null)}>
                                                    <span className={"font-size-20"}><i className="las la-trash-alt"></i></span>
                                                    <span>حذف پیش نمایش</span>
                                                </Button> : null}
                                            {displayVideo && !videoProcessing ?
                                                <Button className={"d-flex btn btn-line-danger me-2 btn btn-secondary font-size-14"} onClick={()=>handleRemoveVideo()}>
                                                    <span className={"font-size-20"}><i className="las la-trash-alt"></i></span>
                                                    <span>حذف ویدیو</span>
                                                </Button>
                                                : null}
                                        </div>
                                    </div>
                                </Col>
                                <Col md={8}>
                                    <h6>پیش نمایش:</h6>
                                    {videoBlob || videoProcessing ?
                                        <div className={"position-relative"}>
                                            {videoProcessing ? <div className={styles.VideoProcessing}>
                                                <SettingOutlined spin />
                                            </div> : null}
                                            <video key={videoFile.name} controls className={"w-100"}>
                                                <source src={videoBlob} type="video/mp4"/>
                                            </video>
                                        </div> : null}
                                    {displayVideo && !videoBlob && !videoProcessing ?
                                        <video controls className={"w-100"}>
                                            <source src={`${Root}/videos/${userId}/${adsId}.mp4`} type="video/mp4"/>
                                        </video> : null}
                                </Col>
                                <Col md={8}>
                                    <h6  style={{margin: "0px"}}>قوانین:</h6>
                                    <ul className={"p-3 font-size-14"}>
                                        <li>ویدیو حتما باید بصورت <Tag color="warning">افقی</Tag> گرفته شود</li>
                                        <li>در ویدیو حتما <Tag color="warning">کالا مورد نظر</Tag> باید نمایش داده شود</li>
                                        <li>در ویدیو میتوان توضیحات <Tag color="warning">مرتبط به کالا</Tag> توضیح داده شود</li>
                                        <li>حداکثر زمان ویدیو <Tag color="warning">30 ثانیه</Tag> می باشد.</li>
                                        <li>تنها <Tag color="warning">یک</Tag> ویدیو مجاز می باشد.</li>
                                        <li>ویدیو حتما باید <Tag color="warning">توسط شما</Tag> گرفته شده باشد و ویدیوهای تبلیغاتی مجاز نمی باشد.</li>
                                        <li>صدا های اضافی و یا موسیقی مجاز نمی باشد</li>
                                        <li>در صورت زمان بردن فرآیند آپلود، ویدیوی خود را <Tag color="warning">کم حجم</Tag> کرده و مجددا تلاش کنید</li>
                                        <li>هرگونه نوشته یا تگ روی ویدیو مجاز نمی باشد</li>
                                    </ul>
                                </Col>
                            </Row>
                        </div>
                    </section>
                    <div className="d-flex justify-content-center align-items-center" style={{marginTop: "15px"}}>
                        <Button
                            type="primary"
                            size="large"
                            loading={processing ? true : false}
                            onClick={()=>handleCheckAds()}>
                                <span>
                                     {adsType === "new" ? "درج آگهی" : "ویرایش آگهی"}
                                </span>
                        </Button>
                    </div>
                </div>
            </SellerLayout>
            <Modal
                title={[
                    <span key={1} className={"modalIconDanger"}>
                    <i className="las la-exclamation-circle"></i>
                </span>,
                    <span key={2}>حذف تصویر</span>
                ]}
                open={deleteModal}
                centered={true}
                onCancel={()=>setDeleteModal(false)}
                onOk={()=>handleRemoveImage(targetFile)}
                okText={"بله"}
                cancelText={"پشیمون شدم"}
            >
                از حذف تصویر مطمئن هستید؟ تصویر حذف شده غیر قابل بازیابی خواهد بود
            </Modal>

            <Modal
                title={
                    <h6>
                    <span className="modalIconSuccess">
                        <i className="las la-check-circle font-size-14"></i>
                    </span>
                        <span className="font-size-14">{adsType === "new" ? "درج آگهی" : "ویرایش آگهی"}</span>
                    </h6>
                }
                open={successModal}
                size="xxl"
                centered={true}
                onOk={()=> adsType === "new" ? (setProcessing(false), setSuccessModal(false), handleRedirect(`/seller/edit/${ads_id}`)) : (setProcessing(false), setSuccessModal(false))}
                onCancel={()=>handleRedirect('/dashboard')}
                okText={adsType === "new" ? "ویرایش آگهی" : "ویرایش مجدد"}
                cancelText={"بازگشت به پنل"}
            >
                {adsType === "edit" ?
                    <p> تغییرات شما با موفقیت اعمال شد و پس از بررسی ناظر روی سایت نمایش داده خواهد شد. با تشکر از صبور شما</p>
                    : adsType === "new" ?
                        <p>آگهی شما با موفقیت ثبت شد و به زودی بررسی و تائید خواهد شد! با تشکر از صبوری شما</p>
                        : null
                }
            </Modal>
        </>
    )
}

export default NewAds;