import {Link} from "react-router-dom";
import logoType from "../../../assets/img/logo_dark.svg";
import React from "react";
import styles from "../../../assets/css/Auth.module.css";

const AuthHeader = () => {
    return (
        <header>
            <figure className="d-flex align-items-center justify-content-center">
                <Link to="/">
                    <div className={styles.SellerLogo}>
                        <img width="90" height="48" alt="" src={logoType} className="logotype"/>
                    </div>
                </Link>
            </figure>
        </header>
    )
}

export default AuthHeader;