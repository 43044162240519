import {Steps} from "antd";
import React from "react";
import {MobileOutlined, SafetyCertificateOutlined} from "@ant-design/icons";

const LoginSteps = ({current}) => {

    const StepsItems = [
        {
            key: 1,
            title: 'شماره موبایل',
            icon: <MobileOutlined />,
        },
        {
            key: 2,
            title: 'تائید',
            icon: <SafetyCertificateOutlined />,
        }
    ];

    return (
        <Steps
            responsive={false}
            current={current}
            items={StepsItems}
        />
    )
}

export default LoginSteps;