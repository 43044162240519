import React, {useEffect, useState} from 'react';
import AuthLayout from "../../layout/auth";
import styles from "../../assets/css/Seller.module.css";
import {postSellerRegisterApi} from "../../api/api";
import {message, Input, Button, Select, Form, Row, Col} from "antd";
import {Link} from "react-router-dom";
import Style from "../../assets/css/Seller.module.css";
import {ArrowUpOutlined} from "@ant-design/icons";

const Register = () => {

    const [form] = Form.useForm();
    const [processing, setProcessing] = useState(false);
    const genderOptions = [
        { label: 'آقا هستم', value: 1 },
        { label: 'خانم هستم', value: 2 }
    ];
    const userTypeOptions = [{label: 'شخصی', value: 1}, {label: 'شرکت/فروشگاه', value: 2}];

    useEffect(()=>{
        getGeo();
        document.title = "نوبازار - ثبت نام"
    },[]);

    const handleRegister = async(values) => {
        setProcessing(true)
        await postSellerRegisterApi(values).then(()=>{
            message.success("عضویت با موفقیت انجام شد")
            setProcessing(false)
        }).catch(()=>{
            setProcessing(false)
            message.error("کاربری با این شماره قبلا ثبت نام کرده")
        });
    }

    const getGeo = () => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    //setFormInputs({...formInputs, latlong: `${position.coords.latitude},${position.coords.longitude}`})
                },
                (error) => {

                }
            )
        }
    }

    return (
        <AuthLayout>
            <div className="d-flex">
                <ArrowUpOutlined style={{ fontSize: '26px', marginLeft: '10px' }} />
                <h1 className={Style.AuthTitle}>ثبت نام</h1>
            </div>
            <label className={styles.Required}>مشخصات</label>
            <Form
                layout="vertical"
                onFinish={handleRegister}
                form={form}
            >
                <Row gutter={10}>
                    <Col span={12}>
                        <Form.Item
                            size="large"
                            name="name"
                            label="نام"
                            rules={[
                                {
                                    required: true,
                                    message: 'لطفا نام خود را وارد نمائید',
                                }
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            size="large"
                            name="lastname"
                            label="نام خانوادگی"
                            rules={[
                                {
                                    required: true,
                                    message: 'لطفا نام خانوادگی خود را وارد نمائید',
                                }
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={10}>
                    <Col span={12}>
                        <Form.Item
                            name="gender"
                            label="جنسیت"
                            rules={[
                                {
                                    required: true,
                                    message: 'لطفا جنسیت خود را انتخاب نمائید',
                                }
                            ]}
                        >
                            <Select
                                size="large"
                                className="w-100"
                                placeholder="انتخاب کنید"
                                showSearch={false}
                                options={genderOptions}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="user_type"
                            label="نوع کاربری"
                            rules={[
                                {
                                    required: true,
                                    message: 'لطفا نوع کاربری خود را انتخاب نمائید',
                                }
                            ]}
                        >
                            <Select
                                size="large"
                                className="w-100"
                                placeholder="انتخاب کنید"
                                showSearch={false}
                                options={userTypeOptions}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item
                    label="شماره موبایل"
                    name="tel"
                    rules={[
                        {
                            required: true,
                            message: 'لطفا شماره موبایل خود را وارد نمائید',
                        },
                        {
                            pattern: /^09\d{9}$/,
                            message: 'شماره موبایل معتبر نمی باشد',
                        }
                    ]}
                >
                    <Input
                        maxLength={11}
                        size="large"
                        placeholder="09---------"
                        className="w-100 dir-ltr"
                        onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                                e.preventDefault();
                            }
                        }}
                        onChange={(e) => {
                            const onlyNumbers = e.target.value.replace(/\D/g, "").slice(0, 11);
                            form.setFieldsValue({ tel: onlyNumbers });
                        }}
                    />
                    <div className={styles.Hint}>
                        توضیح: شماره موبایل شما همان نام کاربری شما محسوب خواهد شد، پس از صحت آن اطمینان حاصل کنید.
                    </div>
                </Form.Item>
                <Form.Item
                    name="mail"
                    label="پست الکترونیک"
                    rules={[
                        {
                            type: 'email',
                            message: 'پست الکترونیک معتبر نیست',
                        },
                        {
                            required: true,
                            message: 'لطفا پست الکترونیک را وارد نمائید',
                        },
                    ]}
                >
                    <Input
                        size="large"
                        type="text"
                        className="dir-ltr"
                    />
                </Form.Item>
                <Form.Item>
                    <Button
                        size="large"
                        type="primary"
                        htmlType="submit"
                        danger
                        loading={processing}
                        className="w-100"
                    >
                        <span>ثبت نام</span>
                    </Button>
                </Form.Item>
            </Form>

            <div style={{marginTop: "15px"}} className={Style.Hint}>ثبت نام شما به منظور پذیرفتن قوانین نوبازار می باشد</div>
            <div className="d-flex">
                <Link className="font-size-14" target="_blank" to={"/content/rules"}>قوانین</Link>
                <span style={{margin: "0 5px"}}></span>
                <Link className="font-size-14" target="_blank" to={"/content/privacy"}>حریم خصوصی</Link>
            </div>
        </AuthLayout>
    )
}

export default Register;